.main {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    background-color: #fff;
    border-radius: 7px;
    background-image: url("../../images/covers/nitzan-black-and-white-01.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
