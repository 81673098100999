.footer__title{
    margin: 0;
    padding: 0;
    font-family: 'Rubik-bold', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.15em;
}

@media screen and (max-width: 450px) {
    .footer__title {
        font-size: 10px;
        line-height: 12px;
    }    
}