.app__container{
    max-width: 1100px;
    height: 100vh;
    margin: 0px auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media screen and (max-width: 1150px) {
    .app__container {
        margin: 0 20px;
    }    
}

@media screen and (max-width: 281px) {
    .app__container {
        margin: 0 3px;
    }    
}
  