.main__menu-item {
    width: 280px;
    height: 70px;
    margin: 12px 15px;
    padding: 0;
}

@media screen and (max-width: 281px) {
    .main__menu-item {
        width: 240px;
    }
}