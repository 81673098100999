.calculator__text {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    word-spacing: 0.05em;
    margin: 10px 0 15px;
    padding: 0;
}

.calculator__text::selection {
    background-color: #ffa;
}

@media screen and (max-width: 400px) {
    .calculator__text {
        font-size: 16px;
        line-height: 18px;
    }
}