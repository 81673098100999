.calculator__table__cell {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: rgba(150, 0, 0, 1);
    padding: 5px;
    text-align: center;
}

.calculator__table__cell::selection {
    background-color: #ffa;
}

@media screen and (max-width: 400px) {
    .calculator__table__cell {
        font-size: 10px;
        line-height: 12px;
        padding: 1px;
    }
}