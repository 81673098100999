.text__title {
    font-family: 'Rubik-bold', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 22px;
    margin: 10px;
    padding: 0;
}

.text__title::selection {
    background-color: #ffa;
}