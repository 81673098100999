.training-plans__text {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.12em;
    word-spacing: 0.16em;
    margin: 15px 25px 2em;
    padding: 0;
}

.training-plans__text::selection {
    background-color: #ffa;
}

@media screen and (max-width: 400px) {
    .training-plans__text {
        font-size: 16px;
        line-height: 18px;
    }
}