.page-not-found {
    display: flex;
    flex-direction: column;
}

.page404Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 100px 0 0;
}