
.footer{
    width: 100%;
    margin: 10px 0;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    user-select: none;
}
