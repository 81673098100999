.running-timer {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
    position: relative;
}

.running-timer__title {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 2em;
}

.running-timer__title::selection {
    background-color: #ffa;
}

.running-timer__text {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 1.5em;
}

.running-timer__text2 {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 1.0em;
}

.running-timer__text::selection {
    background-color: #ffa;
}

.running-timer__block {
    width: 80%;
    margin: 0 auto;
    padding: 0;
}

.running-timer__time {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 7em;
}
.running-timer__time::selection {
    background-color: #ffa;
}

.running-timer__button {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 1em;
    width: 80px;
    background-color: #640000;
    color: #ffa;
    border: 3px solid #640000;
    border-radius: 10px;
    position: absolute;
        top: 5px;
        right: 5px;
}
.running-timer__button::selection {
    background-color: #ffa;
}
