.training-plans__form {
    width: 70%;
    margin: 25px auto 0;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    /* background-color: #aaa; */
    color: rgba(150, 0, 0, 1);
    font-size: 18px;
    line-height: 24px;
}

@media screen and (max-width: 810px){
    .training-plans__form {
        width: 90%;
    }
}

@media screen and (max-width: 500px){
    .training-plans__form {
        width: 95%;
    }    
}
