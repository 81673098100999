.calculator__text_list-item {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 18px;
}

.calculator__text_list-item::selection {
    background-color: #ffa;
}

@media screen and (max-width: 400px) {
    .calculator__text_list-item {
        font-size: 16px;
        line-height: 18px;
    }
}
