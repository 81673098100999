.text__description {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 18px;
    margin: 15px 25px;
    padding: 0;
}

.text__description::selection {
    background-color: #ffa;
}