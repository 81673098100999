.app {
  width: 100%;
  min-width: 300px;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #640000;
  color: rgba(255, 230, 230, 1);
  direction: rtl;
}
