.running-progress-bar {
    width: 90%;
    height: 20%;
    /* background-color: rgba(255, 255, 255, 0.4); */
    border: 7px solid #ffa;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.progress {
    height: 100%;
    background-color: rgba(255, 170, 0, 0.6);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
        right: 0;
}

.progress__text {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 1.1;
    color: #fff;
    letter-spacing: 0.12em;
    word-spacing: 0.16em;
    margin: 0;
    padding: 0;
}