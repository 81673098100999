.main__menu-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    padding: 10px 5px;
    color: #960000;
    background-color: transparent;
    border: 3px solid rgba(100, 0, 0, 1);
    border-radius: 10px;
    user-select: none;
}

.main__menu-button:hover {
    background-color: rgba(255, 200, 200, 0.8);
    box-shadow: 10px 10px 10px #aaa;
    border-color: #ffa;
}