.training-plans__form__input {
    width: 60%;
    margin: 0 10px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid rgba(150, 0, 0);
    outline: 0;
    color: rgba(150, 0, 0);
}

.training-plans__form__input::selection {
    background-color: #ffa;
}

@media screen and (max-width: 880px){
    .training-plans__form__input {
        flex-direction: column;
        width: 95%;
    }    
}
