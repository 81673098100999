.training-plans__form__data{
    border: 0px solid #000;
    width: 90%;
    margin: 0 auto 10px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.training-plans__form__data::selection {
    background-color: #ffa;
}

@media screen and (max-width: 880px){
    .training-plans__form__data{
        flex-direction: column;
        width: 100%;
    }    
}