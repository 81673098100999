.footer__small-logo{
    width: 40px;
    margin: 0 0 0 15px;
    padding: 0;
}

@media screen and (max-width: 450px) {
    .footer__small-logo {
        width: 25px;
    }
}